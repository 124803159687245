<template>
  <div :class="classes">
    <label :hidden="label != null || label != ''">{{label}}</label>
    <select v-model="val" @change="$emit('update:value', $event.target.value)" class="form-control" id>
      <option :value="option.value" v-for="(option, index) in options" :key="index">{{option.text}}</option>
    </select>
    <small class="text-danger"></small>
  </div>
</template>

<script>
export default {
    props: {
      label: {
        type: String
      },
      error: {
        type: Array
      },
      options: {
        required: true
      },
      classes: {
        type: String,
        default: 'form-group'
      },
      value: {
        required: true
      }
    },
    data() {
        return {
            val: this.value
        }
    }
}
</script>